// Polyfill
import "navigator.sendbeacon";
import { v4 as uuidv4 } from "uuid";

// Load ID is constant for this pageload, used for correlation
const loadID = uuidv4();

// Update download link for Android.
function updateDownloadLink() {
  // https://stackoverflow.com/a/28236817
  const isAndroid = /(android)/i.test(navigator.userAgent);

  const href = (document.querySelector("#download") as HTMLAnchorElement).href;

  // Don't overwrite donwload link if it's a OneLink
  if (isAndroid && !href.startsWith("https://goody-links.onelink.me")) {
    // Update link otherwise
    (document.querySelector("#download") as HTMLAnchorElement).href =
      "https://play.google.com/store/apps/details?id=com.ongoody.goody";
  }
}

// Return the pathname (tag).
function getPathname() {
  let pathname = window.location.pathname;
  if (pathname.startsWith("/")) {
    pathname = pathname.substring(1);
  }
  return pathname;
}

// Record an event.
function emitEvent(evt) {
  const data = new FormData();
  data.append("event", evt);
  data.append("tag", getPathname());
  data.append("load_id", loadID);

  const param = document
    .querySelector("meta[name=csrf-param]")
    .getAttribute("content");
  const token = document
    .querySelector("meta[name=csrf-token]")
    .getAttribute("content");
  data.append(param, token);

  navigator.sendBeacon("/record_event", data);
}

function emitOpenBeacon() {
  emitEvent("OPEN");
}

// Attach the event recorder for CLICK to the download button.
function attachDownloadEvent() {
  document.querySelector("#download").addEventListener("click", (e) => {
    emitEvent("CLICK");
  });
}

// On non-tag (root) pages, automatically redirect to the store in 300ms.
function autoRedirect() {
  if (
    getPathname() === "" ||
    getPathname().toUpperCase() === "DELIVERED" ||
    getPathname().toUpperCase() === "CHOCOLATE" ||
    getPathname().toUpperCase() === "MILK" ||
    getPathname().toUpperCase() === "TWENTY" ||
    getPathname().toUpperCase() === "BDAY" ||
    getPathname().toUpperCase() === "HOLIDAYFLYER"
  ) {
    setTimeout(() => {
      emitEvent("REDIRECT");
      window.location.href = (document.querySelector(
        "#download"
      ) as HTMLAnchorElement).href;
    }, 300);
  }
}

function init() {
  updateDownloadLink();
  emitOpenBeacon();
  attachDownloadEvent();
  autoRedirect();
}

if (document.readyState !== "loading") {
  init();
} else {
  document.addEventListener("DOMContentLoaded", () => {
    init();
  });
}
